[data-amplify-theme='default-theme'] {
  width: 100%;
}

.amplify-flex.liveness-detector-check > div:first-of-type {
  display: none;
}

.amplify-flex.amplify-liveness-overlay.amplify-liveness-instruction-overlay {
  padding: 5px !important;
}

.amplify-flex.amplify-liveness-toast__message {
  font-family: 'DB Helvethaica X';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.15px;
  color: #00008f;
}

.amplify-liveness-toast.amplify-liveness-toast--primary {
  background-color: #ffffff;
}

.amplify-liveness-match-indicator__bar:after {
  background: #00008f !important;
}

.amplify-liveness-toast.amplify-liveness-toast--default.amplify-liveness-toast--medium,
.amplify-liveness-recording-icon-container {
  display: none;
}

.amplify-button.amplify-field-group__control.amplify-button--primary {
  background: #00008f !important;
  width: 82px;
  height: 82px;
  border-radius: 100%;
}

.amplify-liveness-freshness-canvas {
  display: block;
  position: absolute;
  height: 2000px;
  width: 2000px;
}

.amplify-liveness-oval-canvas--mobile {
  position: absolute;
}

.amplify-liveness-camera-module {
  position: relative;
  overflow: hidden;
}

.amplify-liveness-cancel-button {
  display: none;
}
