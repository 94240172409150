#batch-calendar {
  width: 451px;
  min-width: 451px;
  overflow: auto;

  .fc-theme-standard td {
    border-color: #d5d4df;
  }
  .fc-theme-standard .fc-scrollgrid {
    border-color: #d5d4df;
  }
  .fc-scrollgrid-section-header {
    display: none;
  }

  .fc .fc-scrollgrid-section-body table {
    height: auto !important;
  }

  .fc .fc-bg-event {
    z-index: 4 !important;
    background: transparent !important;
    opacity: 1 !important;
  }

  .fc-daygrid-day-frame .fc-event-today {
    border-radius: 0 !important;
  }

  .fc-daygrid-day-frame::before {
    display: none;
  }

  /* all day */
  .fc-daygrid-day-frame {
    width: 64px !important;
    height: 64px !important;
    min-height: 64px !important;
    max-height: 64px !important;
  }

  .fc-daygrid-day-top {
    width: auto;
    height: 100%;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }

  table[role='presentation'] {
    width: 100% !important;
    height: 'auto' !important;
  }

  td .fc-day {
    background-color: #ffffff !important;
    border-color: #d5d4df;
    width: 64px !important;
    height: 64px !important;
  }

  .fc-daygrid-day-events {
    display: none;
  }

  .fc .fc-daygrid-day-number {
    padding: 0;
    height: 24px !important;
  }

  .fc-day-today .fc-daygrid-day-number {
    color: #333333;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body3b {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
  }

  .fc-v-event {
    background: unset !important;
    border: unset !important;
    cursor: pointer;
  }

  .fc .fc-timegrid-slot-minor {
    border: none !important;
  }

  .fc-scroller {
    overflow: hidden !important;
    margin-bottom: 0px !important;
  }

  .fc-scroller::-webkit-scrollbar {
    width: 0px;
  }
}
@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 1280px) {
  #batch-calendar {
    width: 548px;
    min-width: 548px;
    overflow: auto;

    td .fc-day {
      width: 78px !important;
      height: 78px !important;
    }

    .fc-daygrid-day-frame {
      width: 78px !important;
      height: 78px !important;
      min-height: 78px !important;
      max-height: 78px !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  #batch-calendar {
    width: 304px;
    min-width: 304px;
    overflow: auto;

    td .fc-day {
      width: 43px !important;
      height: 43px !important;
    }

    .fc-daygrid-day-frame {
      width: 43px !important;
      height: 43px !important;
      min-height: 43px !important;
      max-height: 43px !important;
    }

    .body3b {
      margin: 0;
      font-size: 10px;
      line-height: 12px;
    }

    .fc .fc-daygrid-day-number {
      font-size: 14px;
      line-height: 16px;
      height: 16px !important;
    }
  }
}
