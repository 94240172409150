.fc .fc-popover {
  z-index: 100 !important;
}
.fc-event-main {
  width: 100%;
  cursor: pointer;
}
.fc-event-main-frame {
  width: 100%;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: unset;
  cursor: pointer;
}
/* .fc-h-event {
  background-color: unset !important;
  border: unset !important;
  cursor: pointer;
} */

/* all day */
.fc-daygrid-day-frame .fc-event-today {
  width: auto !important;
  /* height: 30px !important; */
  border-radius: 3px !important;
  /* margin: 4px 4px 0px 4px !important; */

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
  background-color: #ffffff !important;
  /* border: 1px solid #70ad47 !important; */
  white-space: break-spaces;
}

.fc-h-event {
  border: unset !important;
  background-color: unset !important;
}
.event-box-img {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16px;
  height: 16px;
  /* border: 1px solid #ffffff; */
  border-radius: 64px;
}

/* th[role='row'] {
  background-color: #00008f !important;
}*/

.event-work-type-default {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  margin-right: 4px;
}
.event-work-type-holiday {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  margin-right: 4px;
}
.event-content-default {
  display: flex;
  padding: 4px !important;
  align-items: flex-start;
}
.text-default {
  word-break: break-all;
  display: flex;
  width: 100%;
}

table[role='presentation'] {
  width: 100% !important;
}

th .fc-timegrid-axis {
  background-color: #00008f !important;
}
th .fc-scrollgrid-sync-inner {
  background-color: #00008f !important;
  color: #ffffff;
}
th .fc-scroller-harness {
  background-color: #00008f !important;
  color: #ffffff;
  max-height: 50px;
}
.day-off {
  color: #333333 !important;
}
.event-timeGridWeek {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 4;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-calendar-view {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-default {
  background-color: #00008f;
  color: #ffffff;
  border: 1px solid #00008f;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  /* margin: 2px; */
}
.fc .fc-daygrid-event {
  margin: 2px;
}
.event-default-day-off {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #70ad47;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  /* padding: 2px; */
}

/*
.fc-scrollgrid-section .fc-scrollgrid-section-body .fc-scroller-harness {
  background: rgba(73, 118, 186, 0.08) !important;
} */

/* fc-daygrid-day-frame fc-scrollgrid-sync-inner */
td .fc-day {
  background-color: #ffffff;
}
.fc .fc-day-today {
  background-color: #ffffff !important;
}
.fc .fc-day-disabled {
  background: #ffffff !important;
}
.fc td .fc-day-sun .fc-day-disabled {
  background-color: #f9fff4 !important;
}
.fc td .fc-day-sat .fc-day-disabled {
  background-color: #f9fff4 !important;
}
.fc td .fc-day-sun {
  background-color: #f9fff4;
}
.fc td .fc-day-sat {
  background-color: #f9fff4;
}

.fc-daygrid-day-events {
  margin-bottom: 0px !important;
}
.box-group-month {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-left: 2px;
}
.event-img-month {
  margin-left: 2px;
  margin-right: 4px;
}

.event-main-box {
  cursor: pointer;
  width: 100%;
}
.type-holiday {
  margin-top: 8px !important;
  padding-right: 4px;
}
.event-status-active {
  background-color: #ffffff;
  color: #333333;
  border-radius: 4px;
  border: 1px solid #70ad47;
  /* height: 35px; */
  display: flex !important;
  align-items: center !important;
}
.event-status-inprogress {
  background-color: #00008f;
  color: #ffffff;
  border-radius: 4px;
  border: 1px dashed #00008f;
}
.event-status-canceled {
  background-color: #eeeeee;
  color: #999999;
  border-radius: 4px;
  border: 1px dashed #7f7f7f;
}

.event-status-closed {
  background-color: #00008f;
  color: #ffffff;
  border-radius: 4px;
  border: 1px dashed #00008f;
}

.event-status-announcement {
  background-color: #00008f;
  color: #ffffff;
  border-radius: 4px;
  border: 1px dashed #00008f;
}

.event-status-cancel,
.event-status-cancel_by_self,
.event-status-cancel_by_admin,
.event-status-cancel_examination,
.event-status-cancel_by_manager {
  background-color: #eeeeee;
  color: #999999;
  border-radius: 4px;
  border: 1px dashed #7f7f7f;
}

/* 
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  background-color: rgba(73, 118, 186, 0.08);
}

.fc .fc-timegrid-axis-frame {
  background-color: rgba(73, 118, 186, 0.08);
} */
.fc-daygrid-day-number {
  height: 28px !important;
}
.fc-day-today .fc-daygrid-day-number {
  color: #ffffff;
  border-radius: 50%;
  background-color: #00008f;
  width: 28px !important;
  /* height: 28px !important; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-v-event {
  background: unset !important;
  border: unset !important;
  cursor: pointer;
}

.fc .fc-timegrid-slot-minor {
  border: none !important;
}

.fc-scroller {
  margin-bottom: 0px !important;
}

.fc-scroller::-webkit-scrollbar {
  width: 8px;
}

.fc-scroller::-webkit-scrollbar-track {
  background: #fafafa;
  box-shadow: inset 1px 0px 0px #dedede;
}

.fc-scroller::-webkit-scrollbar-thumb {
  background: #5f5f5f;
  border-radius: 4px;
}

.fc-scroller::-webkit-scrollbar-thumb:hover {
  background: #3d3b3b;
}

.fc-daygrid-body {
  width: 100% !important;
}
.fc-timegrid-body {
  width: 100% !important;
}
.fc-scrollgrid-sync-table {
  width: 100% !important;
}

#my-profile-calendar {
  width: 100%;
  overflow: auto;
  height: 536px;
}

@media only screen and (max-width: 600px) {
  .fc-scrollgrid {
    width: 100% !important;
  }
}
