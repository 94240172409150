.document .slick-prev,
.document .slick-next {
  color: #00008f;
}

.document .slick-dots {
  position: relative;
}

@media print {
  .noPrint {
    display: none;
  }
}
