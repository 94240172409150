@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.carousel-root-mobile {
    .carousel.carousel-slider {
            min-height: 450px !important;
            .slider-wrapper{
                min-height:450px
            }
        }
    }
.carousel-root-laptop {
    .carousel.carousel-slider {
            min-height: 550px !important;
            .slider-wrapper{
                min-height:550px
            }
        }
    }

.css-yl3j80-MuiFormGroup-root  {
    .MuiTypography-root{
        padding-top: 0 !important;
    }
}



