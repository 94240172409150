.rdrDefinedRangesWrapper {
  display: none;
}

.dateRange {
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 99;
}

.dateRangeDrawer .rdrMonthAndYearWrapper {
  padding: 10px 120px 0 10px;
}

.rdrMonth {
  padding: 0.833em;
  padding-top: 0px;
}

.rdrStartEdge {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
  
.rdrEndEdge {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rdrDateDisplayItem{
  border-radius: 4px;
  background-color: #00008F;
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
  color: #ffffff
}

.rdrCalendarWrapper {
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -4px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 4px 14px 2px;;
  font-size: 16px;
  width: 320px;
  border-radius: 4px;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrMonthAndYearPickers select:hover{
  background-color: rgba(73, 118, 186, 0.08);
}

.rdrNextPrevButton:hover{
  background: rgba(73, 118, 186, 0.08);
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 4px; 
  background: #FFFFFF
}

.rdrDayToday .rdrDayNumber span:after{
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #00008F;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    left: 2px;
  }

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    right: 2px;
  }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-width: 1px;
    left: 0px;
  }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
   border-top-right-radius: 4px;
   border-bottom-right-radius: 4px;
   border-right-width: 1px;
   right: 0px;
 }

.rdrDayStartPreview{
   border-top-width: 1px;
   border-left-width: 1px;
   border-bottom-width: 1px;
   border-top-left-radius: 4px;
   border-bottom-left-radius: 4px;
   left: 0px;
 }

 .rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  right: 2px;
  right: 0px;
}

.rdrMonthName {
  display: none;
  font-size: 20px;
  color: #333333;
}

.rdrWeekDay {
  color: #000072;
}