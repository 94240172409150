.slick-slider .slick-slide {
  padding: 0 12px;
}

.slick-slider .slick-list {
  margin: 0 -12px;
}

.slick-slider .slick-track {
  margin-left: 0;
}

.slick-dots li button:before {
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.slick-dots li.slick-active button:before {
  color: #000 !important;
}
